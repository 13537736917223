import { api } from './api'

export const getQRCode = ({
  idSeq,
  label,
  contract,
  ut,
  cor,
  faixa,
  idorigem,
}) => {
  if (faixa) {
    if (idorigem) {
      return api().get(
        `/tree/${idSeq}&${label}&${ut}&${
          cor || '_'
        }&${faixa}&${contract}&${idorigem}/qrcode`
      )
    }
    return api().get(
      `/tree/${idSeq}&${label}&${ut}&${cor}&${faixa}&${contract}/qrcode`
    )
  }
  return api().get(`/tree/${idSeq}&${label}&_&${cor}&_&${contract}/qrcode`)
}
